import React from "react";
import styled from "styled-components";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import { isMobile } from "../../hooks/checkMobile";
import { ItemBlog } from "./Blogs/ItemBlog";
import { Text } from "../../components/Text/Text";

export const Related = ({ data }) => {
  if (!data) return null;
  return (
    <WrapperSection>
      <WrapperTitle>
        <Title
          tag={isMobile ? "h3" : "h4"}
          weight={typography.weight.regular}
          capital="uppercase"
          color={color.netraul.black100}
        >
          RELATED STORIES AND NEWS
        </Title>
      </WrapperTitle>

      <WrapperItem>
        {data?.blog?.document && data?.map((ite, i) => (
          <ItemBlog key={i} data={ite} />
        ))}
      </WrapperItem>
    </WrapperSection>
  );
};

Related.propTypes = {
  title: PropTypes.string,
};
const WrapperItem = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  @media (max-width: 1000px) and (min-width: 651px) {
    gap: 20px;
  }
`;
const WrapperTitle = styled.div`
  margin-bottom: 40px;
`;
const Title = styled(Text)``;
const WrapperSection = styled.div`
  padding-top: 80px !important;
  max-width: 1500px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 100px;
  padding: 0 130px;

  @media (max-width: 1200px) {
    padding: 0 80px;
  }
  @media (max-width: 1000px) {
    margin-bottom: 90px;
    padding: 0 40px;
  }
  @media (max-width: 650px) {
    margin-bottom: 80px;
    padding: 0 20px;
  }
`;
