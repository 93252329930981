import React from "react";
import styled from "styled-components";
import { color } from "../../shared/style";
import PropTypes from "prop-types";
import { Text } from "../../components/Text/Text";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "./RichtextHelper/CustomText";
import linkedin1 from "../../images/linkedin1.svg";
import facebook1 from "../../images/facebook1.svg";
import instagram1 from "../../images/instagram1.svg";
import slack1 from "../../images/slack1.svg";
import twitter1 from "../../images/twitter1.svg";
// import CheckImage from "../../images/checkout-check.png";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
// import Modal from "../../components/Modal/modal";

const RenderContent = ({ type, data }) => {
  switch (type) {
    case "PrismicBlogDetailBodyText":
      return (
        <RichText
          render={data?.primary?.text?.raw}
          htmlSerializer={htmlSerializer}
        />
      );
    case "PrismicBlogDetailBodyImage":
      return (
        <ImageBlog>
          {data?.items?.map((item, i) => (
            <DivImage>
              <Image key={i} src={item?.image1?.url}></Image>
              <ImageCaption>
                <RichText
                  render={item?.caption?.raw}
                  htmlSerializer={htmlSerializer}
                />
              </ImageCaption>
            </DivImage>
          ))}
        </ImageBlog>
      );
    case "PrismicBlogDetailBodyImageWithContent":
      return (
        <ImageWithCaption>
          <ImageExpand
            src={data?.primary?.image1?.url}
            order={data?.primary?.image_position}
          ></ImageExpand>
          <Caption>
            <RichText
              render={data?.primary?.content?.raw}
              htmlSerializer={htmlSerializer}
            />
          </Caption>
        </ImageWithCaption>
      );
    case "PrismicBlogDetailBodyQuote":
      return <RichText />;
    default:
      return <></>;
  }
};

const DivImage = styled.div``;
const ImageCaption = styled(Text)``;
const ImageBlog = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
const Image = styled.img`
  @media (max-width: 650px) {
    width: 100%;
    max-height: 250px;
  }
  /* max-width: fit-content; */
`;
const ImageWithCaption = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
const Caption = styled(Text)``;
const ImageExpand = styled.img`
  max-width: 250px;
  order: ${(props) => (props.order === "left" ? 0 : 1)};
  @media (max-width: 650px) {
    width: 100%;
    max-height: 250px;
  }
  /* max-width: fit-content; */
`;

const ContainerTooltip = styled.div`
  display: none;
  position: absolute;
  left: 150%;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 400;
  text-align: center;
  word-wrap: normal;
  font-size: 12px;
  @media (max-width: 650px) {
    bottom: 150%;
    left: 50%;
    top: initial;
    transform: translateX(-50%);
  }
  &::after {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #000;
    @media (max-width: 650px) {
      top: 100%;
      left: 50%;
      right: initial;
      top: initial;
      transform: translateX(-50%);
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-top: 6px solid #000;
    }
  }
`;

const TooltipLabel = styled.div`
  width: 80px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
`;

export const Content = ({ main_content, title, url, tags }) => {
  const Tooltip = () => (
    <ContainerTooltip>
      <TooltipLabel>Copied link</TooltipLabel>
    </ContainerTooltip>
  );

  const handleShowTooltip = (e) => {
    const target = e.target;
    if (target.closest(".wapIcon")) {
      target.closest(".wapIcon").lastChild.style.display = "inline-block";
      setTimeout(() => {
        target.closest(".wapIcon").lastChild.style.display = "none";
      }, 1000);
    }
  };

  return (
    <WrapperSection>
      <WrapperContent>
        {main_content &&
          main_content?.map((item, i) => (
            <RenderContent key={i} type={item?.__typename} data={item} />
          ))}
        {!main_content.length && <NoData>Empty!</NoData>}
        {/* <RichText render={content?.raw} htmlSerializer={htmlSerializer} /> */}
      </WrapperContent>
      <WrapperLabel>
        <LabelSocial
          tag="h4"
          weight={500}
          color={color.netraul.black100}
          capital="uppercase"
        >
          Share
        </LabelSocial>
      </WrapperLabel>
      <WrapperSocial>
        <Social onClick={handleShowTooltip}>
          <LinkedinShareButton url={url}>
            <Icon src={linkedin1} />
          </LinkedinShareButton>
          <FacebookShareButton url={url}>
            <Icon src={facebook1} />
          </FacebookShareButton>
          <TwitterShareButton url={url} title={title} hashtags={tags}>
            <Icon src={twitter1} />
          </TwitterShareButton>
          <WrapIcon className="wapIcon">
            <Icon
              url={url}
              src={instagram1}
              onClick={() => navigator.clipboard.writeText(url)}
            />
            <Tooltip />
          </WrapIcon>
          <WrapIcon className="wapIcon">
            <Icon
              src={slack1}
              onClick={() => navigator.clipboard.writeText(url)}
            />
            <Tooltip />
          </WrapIcon>
        </Social>
      </WrapperSocial>
    </WrapperSection>
  );
};

Content.propTypes = {
  title: PropTypes.string,
};

const WrapperLabel = styled.div`
  display: none;
  @media (max-width: 1000px) {
    margin-top: 32px;
    margin-bottom: 18px;
    display: block;
  }
`;
const WrapperSocial = styled.div`
  position: absolute;
  height: 100%;
  left: 130px;
  @media (max-width: 1200px) {
    left: 80px;
  }

  @media (max-width: 1000px) {
    position: relative;
    left: 0;
  }
`;
const LabelSocial = styled(Text)``;
const NoData = styled.div``;
const WrapIcon = styled.div`
  position: relative;
`;
const Icon = styled.img`
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
  margin: 15px 0;
  cursor: pointer;
  @media (max-width: 1000px) {
    max-width: 32px;
    max-height: 32px;
    width: 32px;
    height: 32px;
    margin-right: 32px;
  }
  @media (max-width: 650px) {
    margin: 0;
  }
`;
const Social = styled.div`
  padding: 0 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: sticky;
  top: 200px;
  left: 122px;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  border: 1px solid #d2d9e2;

  @media (max-width: 1000px) {
    position: relative;
    left: 0;
    top: 0;
    flex-direction: row;
    border: none;
    padding: 0;
    justify-content: flex-start;
  }
  @media (max-width: 650px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const WrapperContent = styled.div`
  @media (max-width: 1100px) {
    border-bottom: 1px solid ${color.netraul.black10};
    padding-bottom: 32px;
    margin-bottom: 32px;
  }
`;
const WrapperSection = styled.div`
  min-height: 250px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  max-width: 1500px;
  padding: 0 312px;
  display: flex;
  position: relative;

  @media (max-width: 1200px) {
    padding: 0 262px;
  }
  @media (max-width: 1000px) {
    padding: 0 40px;
    flex-direction: column;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;
