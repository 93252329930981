import React from "react";
import styled from "styled-components";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import { Text } from "../../components/Text/Text";
import { Link } from "../../components/Link/Link";
import bannerBlogDetail from "../../images/bannerBlogDetail.png";
import arrowback from "../../images/arrowback.svg";
// import { RichText } from "prismic-reactjs";
// import htmlSerializer from "./RichtextHelper/CustomText";

export const Banner = ({ title, banner }) => {
  const RedirectBlog = () => {
    window.location.href = '/blog';
  }
  if (!title || !banner) return null;
  return (
    <WrapperSection banner={banner}>
      <WrapperContent>
        <Text onClick={RedirectBlog}>
          <Back>
            <Icon src={arrowback} />
            {"stories and News"}
          </Back>
        </Text>
        <Title
          tag="h4"
          weight={typography.weight.regular1}
          capital="uppercase"
          color={color.primary.white}
        >
          {title}
        </Title>
      </WrapperContent>
    </WrapperSection>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
};
const Icon = styled.img`
  width: 14px;
  height: 24px;
  margin-right: 14px;
`
const Title = styled(Text)` 
    margin-bottom: 40px; 
    margin-top: 28px; 
    letter-spacing: -0.015em;
    @media (max-width: 650px) {
      
    margin-bottom: 50px; 
    margin-top: 16px; 
    }
`
const Back = styled(Link)`
    display: flex;
    align-items: center;
    background: transparent;
    font-family: Oxygen;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
    color: #FFFFFF;

`
const WrapperContent = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 312px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (max-width: 1000px) {
      padding: 0 40px;
    }
    @media (max-width: 650px) {
      padding: 0 20px;
    }
`
const WrapperSection = styled.div`
  width: 100%;
  background-image: url(${(props) =>
    props.banner ? props.banner : bannerBlogDetail});
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  margin-top: 90px;
  height: calc(100vh - 90px);
  @media (max-width: 650px) {
    min-height: 500px;
  }
`;