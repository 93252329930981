import React from "react";
import styled from "styled-components";
import { Text } from "../../../components/Text/Text";
import { color, typography } from "../../../shared/style";
import PropTypes from "prop-types";
import { Link } from "../../../components/Link/Link";
import { Author } from "../../../components/AuthorBlog/Author";

export const ItemBlog = ({ data }) => {
  const blog = data?.blog?.document?.data;
  const uid = data?.blog?.document?.uid;
  const handleClick = () => {
    window.location.href = `/${uid}`
  }
  if (!data) return null;
  return (
    <WrapperSide>
      <WrapperItemImage>
        <Image src={blog?.image?.url} />
      </WrapperItemImage>
      <WrapperItemTitle>
        <Title font={typography.type.primary} weight={typography.weight.bold} color={color.netraul.black100}>{blog?.title?.text}</Title>
      </WrapperItemTitle>
      <Author isContent data={{ date: blog?.release_date, name: blog?.author?.text }} />
      <WrapperBtnItem>
        <BtnItem onClick={handleClick}>Read more</BtnItem>
      </WrapperBtnItem>
    </WrapperSide>
  );
};

ItemBlog.propTypes = {
  title: PropTypes.string,
};

const WrapperSide = styled.div`
  cursor: pointer;
  width: calc((100% / 3) - 80px / 3);
  @media (max-width: 650px) {
      width: 100%;
  }
  @media (min-width: 651px) and (max-width: 1000px) {
      width: calc((100% / 3) - 40px / 3);
  }
  &:hover {
  }
`
const WrapperBtnItem = styled.div`
  margin-top: 16px;
`
const BtnItem = styled(Link)`
  color: ${color.primary.blue};
  text-decoration: underline;
  text-underline-offset: 5px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
`
const WrapperItemImage = styled.div`
  width: 100%;
  height: 250px;
  margin-bottom: 24px;
  @media (max-width: 650px) {
    height: 217px;

  }
`
const Image = styled.img`
  width: 100%;
  height: 100%;
`
const WrapperItemTitle = styled.div`
  margin: 16px 0;
`
const Title = styled(Text)`
  cursor: pointer;
  letter-spacing: -0.015em;
  @media (max-width: 650px) {
    font-size: 20px!important;
    line-height: 30px;
  }
`