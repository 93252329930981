import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Header } from '../containers/HeaderContainer/index'
import FooterContainer from '../containers/FooterContainer'
import useApp, { withAppContext } from '../contexts/context';
import SEO from '../components/SEO'
import { useSiteMetadata } from '../hooks/useQuerydata';
import Layout from '../components/Layout';
import { Sidebar } from '../components/Sidebar';
import { graphql } from 'gatsby';
import { BlogDetailContainer } from '../containers/BlogDetailContainer/BlogDetailContainer';

const BlogDetail = ({ pageContext, data, ...props }) => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();
  const dataHeader = allPrismicHeader?.nodes[0]?.data;

  const theme = {
    bgColor: 'white',
  }
  if (!data) return null
  const blog = data.prismicBlogDetail.data;
  const tags = data.prismicBlogDetail.tags;

  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={
          <Header
            toggleLayout={handleToggleLayout}
            data={dataHeader}
          />
        }
        footer={
          <FooterContainer
            data={allPrismicFooter?.nodes[0]?.data}
          />
        }
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={
          <SEO data={blog?.body2} />
        }
      >
        <BlogDetailContainer data={blog} tags={tags} uid={data?.prismicBlogDetail?.uid}/>
      </Layout>
    </ThemeProvider>
  )
}

export default withAppContext(BlogDetail);

export const query = graphql`
  query BlogQuery($id: String) {
    prismicBlogDetail(id: {eq: $id}) {
      data {
        image {
          url
        }
        title {
          html
          text
          raw
        }
        author {
          html
          text
          raw
        }
        release_date
        body {
          ... on PrismicBlogDetailBodyText {
            id
            primary {
              text {
                html
                text
                raw
              }
            }
          }
          ... on PrismicBlogDetailBodyImage {
            items {
              image1 {
                alt
                url
              }
              caption {
                html
                text
                raw
              }
            }
          }
          ... on PrismicBlogDetailBodyQuote {
            primary {
              text {
                html
                text
                raw
              }
            }
          }
          ... on PrismicBlogDetailBodyImageWithContent {
            primary {
              image1 {
                url
                alt
              }
              image_position
              content {
                html
                text
                raw
              }
            }
            slice_type
          }
        }
        body2 {
          ... on PrismicBlogDetailBody2GeneralCard {
            primary {
              title {
                html
                text
                raw
              }
              robot {
                html
                text
                raw
              }
              image {
                url
                alt
              }
              googlebot {
                html
                text
                raw
              }
              favicon {
                alt
                url
              }
              description {
                html
                text
                raw
              }
              bingbot {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicBlogDetailBody2OpenGraph {
            primary {
              og_url {
                url
                type
                link_type
              }
              og_type {
                html
                text
                raw
              }
              og_title {
                html
                text
                raw
              }
              og_site_name {
                html
                text
                raw
              }
              og_locale {
                html
                text
                raw
              }
              og_image_width {
                html
                text
                raw
              }
              og_image_height {
                html
                text
                raw
              }
              og_image {
                alt
                url
              }
              og_description {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicBlogDetailBody2TwitterCard {
            primary {
              title {
                html
                text
                raw
              }
              image {
                alt
                url
              }
              description {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicBlogDetailBody2PinterestProductPin {
            slice_type
            primary {
              title {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
              price
              currency
              availability
            }
          }
        }
        related_blogs {
          blog {
            document {
              ... on PrismicBlogDetail {
                data {
                  title {
                    html
                    text
                    raw
                  }
                  author {
                    html
                    text
                    raw
                  }
                  release_date
                  image {
                    url
                  }
                }
                uid
              }
            }
          }
        }
      }
      tags
      uid
    }
  }
`
