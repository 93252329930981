import * as React from 'react'
import { Elements } from 'prismic-richtext'
import { Link as PrismicLink } from 'prismic-reactjs'
import { Text } from '../../../components/Text/Text'
import { Link } from '../../../components/Link/Link'
import { color, typography } from '../../../shared/style'
import styled from "styled-components";
import ListStyleImage from "../../../images/list-style.png";
//embed link converter

const getId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

// -- HTML Serializer
const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case Elements.heading1: // Heading 1
      return <Text key={key}>{children}</Text>;

    case Elements.heading2: // Heading 2
      return (
        <TextCustome
          tag="h2"
          capital="uppercase"
          // color={color.primary.blue}
          weight={500}
          font={typography.type.code}
          key={key}
        >
          {children}
        </TextCustome>
      );

    case Elements.heading3: // Heading 3
      return (
        <TextCustome
          tag="h3"
          capital="uppercase"
          // color={color.primary.blue}
          weight={500}
          font={typography.type.code}
          key={key}
        >
          {children}
        </TextCustome>
      );

    case Elements.heading4: // Heading 4
      return (
        <TextCustome
          tag="h4"
          capital="uppercase"
          // color={color.primary.blue}
          weight={500}
          font={typography.type.code}
          key={key}
        >
          {children}
        </TextCustome>
      );

    case Elements.heading5: // Heading 5
      return (
        <TextCustome
          tag="h5"
          capital="uppercase"
          // color={color.primary.blue}
          weight={500}
          font={typography.type.code}
          key={key}
        >
          {children}
        </TextCustome>
      );

    case Elements.heading6: // Heading 6
      return (
        <TextCustome
          tag="h6"
          capital="uppercase"
          // color={color.primary.blue}
          weight={500}
          font={typography.type.code}
          key={key}
        >
          {children}
        </TextCustome>
      );

    case Elements.paragraph: // Paragraph
      return (
        <TextCustomePara font={typography.type.primary} key={key}>
          {children}
        </TextCustomePara>
      );

    case Elements.preformatted: // Preformatted
      return <div key={key} dangerouslySetInnerHTML={{ __html: children }} />;

    case Elements.strong: // Strong
      return <strong key={key}>{children}</strong>;

    case Elements.em: // Emphasis
      return <em key={key}>{children}</em>;

    case Elements.listItem: // Unordered List Item
      return <li key={key}>{children}</li>;
    case Elements.oListItem: // Ordered List Item
      return <li key={key}>{children}</li>;

    case Elements.list: // Unordered List
      return <Ul key={key}>{children}</Ul>;

    case Elements.oList: // Ordered List
      return <ol key={key}>{children}</ol>;

    case Elements.image:
      const linkUrl = element.linkTo
        ? element.linkTo.url || element.linkTo
        : null;

      return (
        <p
          key={key}
          className={[element.label, "block-img"].filter(Boolean).join(" ")}
        >
          {linkUrl ? (
            <a
              href={linkUrl}
              target={element.linkTo?.target}
              rel={element.linkTo?.target ? "noopener" : undefined}
            >
              <img src={element.url} alt={element.alt || ""} />
            </a>
          ) : (
            <img src={element.url} alt={element.alt || ""} />
          )}
        </p>
      );

    case Elements.embed: // Embed
      const videoId = getId(element.oembed.embed_url);
      return (
        <div>
          <iframe
            title={videoId}
            width="100%"
            height="350px"
            src={`//www.youtube.com/embed/${videoId}`}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="allowfullscreen"
          ></iframe>
        </div>
      );

    case Elements.hyperlink: // Hyperlinks
      const url = PrismicLink.url(element.data);
      return (
        <Link
          fontFamily={typography.type.primary}
          size={typography.size.s3}
          key={key}
          to={url}
          target={element.data?.target}
        >
          {content}
        </Link>
      );

    case Elements.label: // Label
      return (
        <>
          <TextCustome
            color={color.dark.dark100}
            weight={600}
            font={typography.type.primary}
            key={key}
            className={element.data.label}
          >
            {children}
          </TextCustome>
          <br />
          <br />
        </>
      );

    case Elements.span: // Span
      if (content) {
        return content.split("\\n").reduce((acc, p) => {
          if (acc.length === 0) {
            return [p];
          } else {
            const brIndex = (acc.length + 1) / 2 - 1;
            return acc.concat([<br key={brIndex} />, p]);
          }
        }, []);
      } else {
        return null;
      }

    default:
      // Always include a default that returns null
      return null;
  }
};

const Ul = styled.ul`
  list-style: url(${ListStyleImage});
  list-style-position: outside;
  padding-left: 16px;
  box-sizing: border-box;
  li {
    padding-left: 16px;
  }
`;

const TextCustome = styled(Text)`
  /* font-size: inherit !important;
  line-height: inherit !important;
  font-family: inherit; */
  color: ${color.netraul.black100};
  letter-spacing: -0.015em;
  font-size: 36px !important;
  line-height: 36px !important;
`;
const TextCustomePara = styled.p`
font-weight: ${typography.weight.regular};
  font-size: ${typography.size.s3}px;
  line-height: 26px;
  font-family: ${typography.type.primary};
  padding-bottom: 32px;
  color: ${color.netraul.black100};
  &:last-child {
    padding-bottom: 0;
  }
`;

export default htmlSerializer