import React from 'react'
import styled from "styled-components";
import { Subscribe } from "./Subscribe"
import { Banner } from "./Banner"
import { Info } from "./Info"
import { Content } from "./Content"
import { Related } from "./Related"

export const BlogDetailContainer = ({ data, tags, uid }) => {
  return (
    <WrapperLayout>
      <Banner title={data?.title.text} banner={data?.image?.url} />
      <Info
        tags={tags}
        name={data?.author?.text}
        date={data?.release_date}
      />
      <Content
        main_content={data?.body}
        content={data?.content}
        title={data?.title?.text} url={`draperstartuphouse.com/${uid}`} tags={tags}
      />
      <Related data={data?.related_blogs} />
      <Subscribe />
      {/* <Recent />*/}
    </WrapperLayout>
  )
};

const WrapperLayout = styled.div`
  margin: 0 auto;
  position: relative;
`;
