import React from "react";
import styled from "styled-components";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import { Text } from "../../components/Text/Text";
import {Author} from "../../components/AuthorBlog/Author"

export const Info = ({ tags, name, date }) => {
  if (!tags || !name || !date) return null;
  return (
    <WrapperSection>
      <WrapperContent>
        <Author data={{ date: date, name: name }} />
        <WrapperTag>
          {tags &&
            tags.map((item, i) => (
              <Item key={i}>
                <WrapperName>
                  <Name
                    font={typography.type.primary}
                    color={color.primary.blue}
                    capital="uppercase"
                    weight={typography.weight.bold}
                  >
                    {item}
                  </Name>
                </WrapperName>
              </Item>
            ))}
        </WrapperTag>
      </WrapperContent>
    </WrapperSection>
  );
};

Info.propTypes = {
  title: PropTypes.string,
};

// const AuthorWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
// `;
// const AuthorIcon = styled.img`
//   padding-right: 4px;
// `;
// const Divider = styled.div`
//   line-height: 25px;
//   padding-right: 10px;
//   @media (max-width: 1400px) {
//     display: none;
//   }
// `;
// const AuthorName = styled.div`
//   color: ${color.dark.dark100};
//   padding-right: 10px;
// `;
// const AuthorTime = styled.div`
//   color: ${color.netraul.black70};
// `;
const Name = styled(Text)`
  font-size: 12px !important;
  line-height: 22px;
  cursor: pointer;
`;
const WrapperName = styled.div``;
const Item = styled.div`
  width: fit-content;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 5px 14px;
  background-color: ${color.primary.blue10};
  border-radius: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
`;
const WrapperTag = styled.div`
  margin-bottom: 32px;
  padding: 32px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-bottom: 1px solid ${color.netraul.black10};
`;
const WrapperContent = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 312px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 1200px) {
    padding: 0 262px;
  }
  @media (max-width: 1000px) {
    padding: 0 40px;
  }
  @media (max-width: 650px) {
    padding: 0 20px;
  }
`;
const WrapperSection = styled.div`
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;
  max-width: 1500px;
`;
